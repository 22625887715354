.TableauFarm {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 15px 50px 15px 50px 15px 50px 15px 50px 50px 50px 50px;
  grid-template-areas:
    'd00 d01 d02'
    'l00 l01 l02'
    'd10 d11 d12'
    'l10 l11 l12'
    'd20 d21 d22'
    'l20 l21 l22'
    'd30 d31 d32'
    'l30 l31 l32'
    'l40 l41 l42'
    'l50 l51 l52'
    'l60 l61 l62';
  grid-row-gap: 2px;
}

/* Collapse the borders */
/* .TableauFarm > div {
  margin: 0 -1px -1px 0;
} */
