.Board {
  display: grid;
  grid-template-columns: 1fr 200px 2fr 1fr;
  font-size: small;
  grid-gap: 5px;
  padding: 5px;
}

.activeRed {
  box-shadow: 0px 0 16px rgb(165, 0, 0) inset;
}

.activeYellow {
  box-shadow: 0px 0 16px rgb(170, 167, 0) inset;
}

.Board .col1 {
  display: grid-item;
  grid-column-start: 1;
  grid-column-end: 2;
}
.Board .col2 {
  display: grid-item;
  grid-column-start: 2;
  grid-column-end: 3;
}
.Board .col3 {
  display: grid-item;
  grid-column-start: 3;
  grid-column-end: 4;
}
.Board .col4 {
  display: grid-item;
  grid-column-start: 4;
  grid-column-end: 5;
}

button:not([disabled]) {
  background-color: #1cc6ff;
  border-color: #1cc6ff;
  border-style: outset;
  border-radius: 3px;
}

button:hover:not([disabled]) {
  box-shadow: 0 0 10px #1cc6ff;
  cursor: pointer;
}

button[disabled] {
  background-color: white;
  cursor: not-allowed;
}
