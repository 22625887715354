/* $lightgreen: #b2df8a;
$darkgreen: #33a02c; */

.PreparationsBoard,
.PreparationsBoard > thead > tr > th,
.PreparationsBoard > tbody > tr > td,
.PreparationsBoard > tfoot > tr > th {
  border-collapse: collapse;
  border: 1px solid #9ccf98;
  margin: 0px;
  padding: 0px;
}

.PreparationsBoard {
  margin: 3px 0;
  width: auto;
}

.PreparationsBoard .glow {
  background-color: #9ccf98;
}
