/* $lightgreen: #b2df8a;
$darkgreen: #33a02c; */

.ToolTrack {
  width: 100%;
}

.ToolTrack,
.ToolTrack > tbody > tr > td,
.ToolTrack > thead > tr > th {
  border-collapse: collapse;
  border: 0;
  margin: 0px;
  padding: 0px;
  text-align: center;
}
