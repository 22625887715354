.BuildingsBoard {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-gap: 0px;
  grid-template-rows: 20px 50px 50px 20px 50px 20px 50px 50px 50px 20px 50px 50px 50px;
  grid-template-areas:
    'smallHouseHeading smallHouseHeading'
    'smallHouse1 smallHouse2'
    'smallHouse3 smallHouse4'
    'minorCraftBuildingHeading minorCraftBuildingHeading'
    'minorCraftBuilding1 minorCraftBuilding2'
    'majorCraftBuildingHeading majorCraftBuildingHeading'
    'majorCraftBuilding1 majorCraftBuilding2'
    'majorCraftBuilding3 majorCraftBuilding4'
    'majorCraftBuilding5 majorCraftBuilding6'
    'innTileHeading largeBuildingHeading'
    'innTile1 largeBuilding1'
    'innTile2 largeBuilding2'
    'innTile3 largeBuilding3';
}

.BuildingsBoard .smallHouseHeading {
  grid-area: smallHouseHeading;
}
.BuildingsBoard .smallHouse1 {
  grid-area: smallHouse1;
}
.BuildingsBoard .smallHouse2 {
  grid-area: smallHouse2;
}
.BuildingsBoard .smallHouse3 {
  grid-area: smallHouse3;
}
.BuildingsBoard .smallHouse4 {
  grid-area: smallHouse4;
}

.BuildingsBoard .minorCraftBuildingHeading {
  grid-area: minorCraftBuildingHeading;
}
.BuildingsBoard .minorCraftBuilding1 {
  grid-area: minorCraftBuilding1;
}
.BuildingsBoard .minorCraftBuilding2 {
  grid-area: minorCraftBuilding2;
}

.BuildingsBoard .majorCraftBuildingHeading {
  grid-area: majorCraftBuildingHeading;
}
.BuildingsBoard .majorCraftBuilding1 {
  grid-area: majorCraftBuilding1;
}
.BuildingsBoard .majorCraftBuilding2 {
  grid-area: majorCraftBuilding2;
}
.BuildingsBoard .majorCraftBuilding3 {
  grid-area: majorCraftBuilding3;
}
.BuildingsBoard .majorCraftBuilding4 {
  grid-area: majorCraftBuilding4;
}
.BuildingsBoard .minorCraftBuilding5 {
  grid-area: minorCraftBuilding5;
}
.BuildingsBoard .minorCraftBuilding6 {
  grid-area: minorCraftBuilding6;
}

.BuildingsBoard .innTileHeading {
  grid-area: innTileHeading;
}
.BuildingsBoard .innTile1 {
  grid-area: innTile1;
}
.BuildingsBoard .innTile2 {
  grid-area: innTile2;
}
.BuildingsBoard .innTile3 {
  grid-area: innTile3;
}

.BuildingsBoard .largeBuildingHeading {
  grid-area: largeBuildingHeading;
}
.BuildingsBoard .largeBuilding1 {
  grid-area: largeBuilding1;
}
.BuildingsBoard .largeBuilding2 {
  grid-area: largeBuilding2;
}
.BuildingsBoard .largeBuilding3 {
  grid-area: largeBuilding3;
}

.BuildingsBoard .Building .toolTip {
  background-color: #eccda3;
  border: 1px solid #b99667;

  position: absolute;
  top: -10px; /* - top padding */
  right: 9999px;
  width: 140px;
  margin-right: -150px; /* width + left/right padding */
  padding: 10px;
  background: #eccda3;
  opacity: 0;
  transition: opacity 250ms ease-out;
  z-index: 1;
}

.BuildingsBoard .Building:hover .toolTipRight {
  right: -20px;
  opacity: 1;
}

.BuildingsBoard .Building .toolTipRight:before {
  content: ' '; /* Must have content to display */
  position: absolute;
  top: 18px;
  left: -16px; /* 2 x border width */
  width: 0;
  height: 0;
  margin-top: -8px; /* - border width */
  border: 8px solid transparent;
  border-right-color: #b99667;
}

.BuildingsBoard .Building:hover .toolTipLeft {
  left: -170px;
  opacity: 1;
}

.BuildingsBoard .Building .toolTipLeft:before {
  content: ' '; /* Must have content to display */
  position: absolute;
  top: 18px;
  right: -16px; /* 2 x border width */
  width: 0;
  height: 0;
  margin-top: -8px; /* - border width */
  border: 8px solid transparent;
  border-left-color: #b99667;
}
