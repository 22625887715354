/* $lightblue: #a6cee3;
$darkblue: #1f78b4; */

.RoundBoard > tbody > tr > td,
.RoundBoard > thead > tr > th {
  border: 1px solid #e2cfb2;
  margin: 0px;
  padding: 0px;
  width: 37px;
  text-align: center;
}

.RoundBoard {
  table-layout: fixed;
  border-collapse: collapse;
  border: 1px solid #e2cfb2;
  border-spacing: 0px;
  margin: 3px 0;
  width: auto;
}

.RoundBoard .glow {
  box-shadow: 0px 0 8px #f85 inset;
}
