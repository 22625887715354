.TableauLand {
  min-height: 50px;
  border: 1px solid #b2df8a;
  background-color: #e9f8dc;
}

.TableauLand.flooded {
  border: 1px solid #a6cee3;
  background-color: #dfecf3;
}

.TableauLand.moorNorth,
.TableauLand.moorSouth {
  border-color: #615324;
  background-color: #c4b78e;
}
.TableauLand.dmoorNorth,
.TableauLand.dmoorSouth {
  border-color: #837443;
  background-color: #d6cdad;
}
