/* $lightblue: #a6cee3;
$darkblue: #1f78b4; */

.ActionsBoard {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  /* grid-auto-rows: minmax(100px, auto); */
  /* border-collapse: collapse;
  border: 1px solid #b2cfe2;
  border-spacing: 0px; */
  /* margin: 0; */
}

.ActionsBoard header {
  font-weight: bold;
}

.ActionsBoard header.tools {
  grid-column: 2/5;
}
.ActionsBoard section.action-summer {
  grid-column: 1/2;
}
.ActionsBoard section.action-winter {
  grid-column: 5/6;
}

.ActionsBoard section.tool-winter {
  grid-column: 3/5;
  border: 1px solid #decfe5;
  background-color: #f0e8f5;
}
.ActionsBoard section.tool-both {
  grid-column: 2/5;
  border: 1px solid #decfe5;
  background-color: #f0e8f5;
}
.ActionsBoard section.tool-summer {
  grid-column: 2/4;
  border: 1px solid #decfe5;
  background-color: #f0e8f5;
}
.ActionsBoard section.double-row {
  grid-row-end: span 2;
}
/*
.ActionsBoard button {
  cursor: pointer;
  background-color: #7f7fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.ActionsBoard button[disabled] {
  background-color: #eee;
} */

.ActionsBoard section.not-implemented {
  font-style: italic;
  opacity: 0.2;
}
