.BuildingsBoard .Building {
  position: relative;
  margin: 1px;
  background-color: #eccda3;
  border: 1px solid #b99667;
  font-size: x-small;
}

.TableauFarm .Building {
  position: static;
  background-color: #eccda3;
  border: 1px solid #b99667;
  font-size: x-small;
}
